<template>
  <a-tag :color="ENUM_STATE[state].tagColor">{{ ENUM_STATE[state].label }}</a-tag>
</template>

<script>
import {ENUM_STATE} from "@/model/constant";

export default {
  props: {
    state: String
  },
  data() {
    return {
      ENUM_STATE
    }
  }
}
</script>

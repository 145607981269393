// {
//   "id": "3213879102700257281",
//   "creatorId": "91",
//   "updaterId": "91",
//   "createdTime": "1970-06-28 17:06:02",
//   "updatedTime": "1971-08-16 14:41:10",
//   "name": "外同重基活后观",
//   "icon": "http://dummyimage.com/100x100",
//   "domain": "f.ydaojsoi@qq.com",
//   "description": "那书研平样设九比理管资节理。业花法系主先置阶选其增风表单。做断存切更叫共关为政儿器斗斗题路际。",
//   "state": "RELEASED"
// },

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    showPos: {
      table: false,
      form: false,
    }
  },
  {
    title: '站点标识',
    dataIndex: 'icon',
    align: 'center',
    scopedSlots: {customRender: 'icon'},
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: []
    },
    dataConfig: {}
  },
  {
    title: '站点名称',
    dataIndex: 'name',
    scopedSlots: {customRender: 'name'},
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: [{ required: true, message: '请输入站点名称!' }]
    },
    dataConfig: {}
  },
  {
    title: '站点域名',
    dataIndex: 'domain',
    scopedSlots: {customRender: 'domain'},
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: [{ required: true, message: '请输入站点域名!' }]
    },
    dataConfig: {}
  },
  {
    title: '站点描述',
    dataIndex: 'description',
    scopedSlots: {customRender: 'author'},
    showPos: {
      table: false,
      form: true,
      detail: true,
    },
    formConfig: {
      comp: 'textarea',
      rules: []
    },
    dataConfig: {}
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    scopedSlots: {customRender: 'time'},
    width: 100,
    showPos: {
      table: true,
      form: false,
    }
  },
  {
    title: '更新时间',
    dataIndex: 'updatedTime',
    scopedSlots: {customRender: 'time'},
    width: 100,
    showPos: {
      table: true,
      form: false,
    }
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: {customRender: 'state'},
    showPos: {
      table: true,
      form: false,
    }
  },
]

<template>
  <a-drawer :visible="visible" @close="close" width="50%" :mask-closable="false"
            :get-container="false" :closable="false"
            :wrap-style="{ position: 'absolute' }"
            :header-style="{position: 'sticky', top: 0, zIndex: 100,}">
    <div slot="title" class="title-container">
      <a-page-header title="站点管理" :sub-title="edit ? '编辑' : '详情'" style="border: none;width: 100%;padding: 8px 0;">
        <template slot="extra">
          <a-button v-if="edit" type="primary"
                    :loading="formLoading" icon="check" @click="handleSave">保存</a-button>
          <a-button v-else type="primary" icon="form" @click="toEdit">编辑</a-button>
          <a-button icon="close" @click="close">取消</a-button>
        </template>
      </a-page-header>
    </div>
    <div>
      <a-form v-if="edit" :form="form" layout="vertical">
        <a-row :gutter="16">
          <a-col v-for="field in formFields" :key="field.dataIndex" :span="field.formConfig.span || 24">
            <a-form-item :label="field.title">
              <a-textarea v-if="field.formConfig.comp === 'textarea'"
                          v-decorator="[ field.dataIndex, { rules: field.formConfig.rules }, ]"
                          :auto-size="{ minRows: 2, maxRows: 6 }"
                          :placeholder="`请输入${field.title}`"
                          :maxLength="field.formConfig.maxLen || 1000" />
              <a-input v-else
                  v-decorator="[ field.dataIndex, { rules: field.formConfig.rules }, ]"
                  :placeholder="`请输入${field.title}`" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template v-else>
        <a-descriptions layout="vertical" bordered>
          <a-descriptions-item v-for="field in detailFields" :key="field.dataIndex" :label="field.title">
            {{ detailData[field.dataIndex] }}
          </a-descriptions-item>
        </a-descriptions>
        <detail-footer/>
      </template>
    </div>
  </a-drawer>
</template>

<script>
import {detail, save} from "@/api/service/website";
import {isEmpty} from "lodash";
import {formFieldHandler} from "@/utils/form";
import {columns} from "@/views/website/options";
import {detailFieldHandler} from "@/utils/detail";
import DetailFooter from "@/components/ActDrawer/DetailFooter.vue";
import {ENUM_STATE} from "@/model/constant";

export default {
  components: {DetailFooter},
  data() {
    return {
      visible: false,
      edit: false,
      formFields: formFieldHandler(columns),
      detailFields: detailFieldHandler(columns),
      form: null,
      formLoading: false,
      detailData: {}
    }
  },
  methods: {
    open({edit, record}) {
      this.visible = true
      this.form = this.$form.createForm(this, {name: 'website_form'})
      this.edit = edit
      if(!isEmpty(record)) {
        this.detailData = record
      }
      if (edit && !isEmpty(this.detailData)) {
        this.recoveryData()
      }
    },
    close() {
      this.form.resetFields()
      Object.assign(this.$data, this.$options.data())
    },
    toEdit() {
      this.edit = true
      this.recoveryData()
    },
    recoveryData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: this.detailData.name,
          domain: this.detailData.domain,
          description: this.detailData.description
        })
      })
    },
    async reloadDetail(result) {
      const { success, data } = await detail({id: this.detailData.id || result.id})
      if(success) {
        this.detailData = data
      }
    },
    handleSave() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          this.doSave(values)
        }
      })
    },
    async doSave(values) {
      const params = {
        ...values,
        state: ENUM_STATE.RELEASED.value,
      }
      if (this.detailData.id) {
        params.id = this.detailData.id
      }
      this.formLoading = true
      const {success, data} = await save(params)
      if (success) {
        this.$notification.success({
          message: '操作成功',
          duration: 2,
          onClose: () => {
            this.edit = false
            this.formLoading = false
            this.reloadDetail(data)
            this.$emit('success')
          }
        });
      }
    }
  }
}
</script>

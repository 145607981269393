<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">{{ moduleName }}</h5>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
          <a-button type="primary" icon="plus" @click="toEdit({})">创建{{ moduleName }}</a-button>
        </a-col>
      </a-row>
    </template>
    <a-table :loading="tableLoading" :columns="finalColumns" :data-source="datasource"
             :rowKey="record => record.id">
      <template slot="icon" slot-scope="text">
        <a-avatar shape="square" :src="text" />
      </template>
      <template slot="name" slot-scope="text">
        {{ text }}
      </template>
      <template slot="domain" slot-scope="text">
        <a>{{ text }}</a>
      </template>
      <template slot="time" slot-scope="text">
        <time-tag :time="text" />
      </template>
      <template slot="state" slot-scope="text">
        <state-tag :state="text"/>
      </template>
      <template slot="action" slot-scope="row">
        <a-space>
          <a-button type="link" size="small" :data-id="row.key" icon="eye" @click="toDetail(row)">
            查看
          </a-button>
          <a-button type="link" size="small" :data-id="row.key" icon="form" @click="toEdit(row)">
            编辑
          </a-button>
          <a-button type="link" size="small" class="danger-button" :data-id="row.key" icon="delete" @click="doDelete(row)">
            删除
          </a-button>
        </a-space>
      </template>
    </a-table>
    <ActDrawer ref="ActDrawerRef" @success="loadData" />
  </a-card>
</template>

<script>
import {query, remove} from "@/api/service/website";
import {ENUM_STATE} from "@/model/constant";
import ActDrawer from "@/views/website/ActDrawer.vue";
import {tableMixin} from "@/mixin/table";
import {columns} from "@/views/website/options";
import TimeTag from "@/components/table/time-tag.vue";
import StateTag from "@/components/table/state-tag.vue";

export default {
  components: {StateTag, TimeTag, ActDrawer},
  mixins: [tableMixin],
  data() {
    return {
      moduleName: '站点',
      columns,
      ENUM_STATE,
    }
  },
  methods: {
    query,
    remove,
    toEdit(record) {
      this.$refs.ActDrawerRef.open({
        edit: true,
        record
      })
    },
    toDetail(record) {
      this.$refs.ActDrawerRef.open({
        edit: false,
        record
      })
    },
  },
}

</script>
